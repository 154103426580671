.timegate-header {
  font-size: 18px;
  font-weight: bold;
}

.timegate-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .timegate-label {
    font-weight: bold;
    margin-right: 15px;
    
    p {
      margin-bottom: 0;
    }
  }

  .rw-widget-input {
    display: none;
  }

  .rw-datetime-picker {
    max-width: 300px;
  }

  .rw-popup {
    min-width: 125px;
  }

  .timegate-component {
    display: flex;
    
    .timegate-component-input {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      color: #333;
      background: #FFFFFF;
      border: #ccc 1px solid;
    }
  }
}