.toast {
  &.tm {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    margin: 0;
    padding: 1.5rem 1.75rem;
    min-width: 250px;
    background-color: $lightBlue;
    color: #fff;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      height: 2rem;
      width: 2rem;
      font-weight: 100;
      text-shadow: none;
      color: #000;
    }

    .toast-body {
      padding: 0;
    }
  }
}
