.wellness-center {
  .tm-card.tm-card-stepper .tm-card-content .form-group {
    max-width: 431px;
  }
}

.birthday-fields {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media #{$mq-min-767} {
    .tm-dropdown {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }

  .tm-dropdown {
    margin-right: 0;
  }
}
