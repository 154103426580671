.skinny {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.fat {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}
