// TODO: Using !important is a code smell, needs refactoring
.form-control {
  background-color: $inputFill;
  //padding: 25px 10px;
  height: 50px;
  border: none;
  border-radius: 10px;
  margin: 20px 0;

  &:focus {
    outline: none;
    box-shadow: none;
    background-color: $inputFill;
  }
  &.has-error {
    border: solid 1px $red;
  }
}
.input::placeholder {
  color: #bababa;
}

.label {
  margin-bottom: 0;
  font-weight: bold;
}

.change-password,
.reset-password {

  .breadcrumb {
    padding-top: 0;
  }

  .form-group {
    align-items: flex-start;
    width: auto;
    margin-right: 0;
  }
}

.react-tel-input {
  input[type=text],
  input[type=tel] {
    height: 50px !important;
    width: 100% !important;

    &.participant-entry-field {
      border: none;
    }
  }

  .participant-entry-field + .flag-dropdown {
    border: none;
  }
}
