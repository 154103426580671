@keyframes fadeIn {
  from {
    opacity: 0;
    left: 35px;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

.hidden {
  display: none;
}

.opacity-hidden {
  opacity: 0 !important;
  animation: none !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.blue-fill-icon {
  fill: $midBlue !important;
  cursor: pointer;
  &:first-of-type {
    margin-right: 1rem;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.sibling-border-top + .sibling-border-top {
  border-top: 1px solid $lightMidGrey;
  padding-top: 1rem;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.clickable {
  cursor: pointer;
}

.justify-content-flex-end {
  justify-content: flex-end;
}
