.error-text {
  font-weight: bold;
  color: #f00;
}

a {
  color: $linkBlue;
  transition: all 0.2s ease-out;

  &:hover {
    color: $lightBlue;
  }

  &.up-arrow {
    font-weight: bold;
    margin-left: 8px;
    position: relative;

    &:before {
      background-image: url('../../assets/imgs/up-arrow.png');
      content: '';
      height: 19px;
      left: -28px;
      position: absolute;
      width: 19px;
    }
  }
}

p {
  font-size: $font-size-md;
  line-height: 1.7rem;
  margin-bottom: 1.7rem;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

.content {
  ol, ul {
    margin-bottom: 30px;

    li {
      margin-bottom: 0.8rem;
    }

    a {
      li {
        font-weight: 500;
      }
    }
  }
}

.zmdi {
  color: $darkBlue;
  font-size: 22px;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.no-list-style {
  list-style: none;
}

.caption {
  font-size: $font-size-sm;
  color: $contentGrey;
}
