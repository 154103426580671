.header {
  border-top: 5px solid $darkBlue;
  box-sizing: border-box;
  margin-bottom: 40px;
  max-height: 75px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    flex-grow: 0;
    flex-shrink: 0;
    width: auto;
    height: 75px;
  }
  .user {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    font-size: 0.8rem;
    line-height: 0.98rem;

    .username {
      font-weight: bold;
      color: $darkGrey;
    }

    .role {
      color: rgba(0, 0, 0, 0.7);
      font-style: italic;
    }

    a {
      color: $darkBlue;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .avatar {
      height: 50px;
      width: 50px;
      margin-left: 20px;
    }
  } // end .user
} // end header
