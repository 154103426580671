.bottom-border {
  display:block;
  border:none;
  color:white;
  height:1px;
  background:black;
  background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 600, from($midGrey), to(#fff0));
  margin-top: 4rem;
}

.button-container-indicator-select {
  margin-left: 5rem;
  margin-bottom: 4rem;
}

.content-indicator-select-form {
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
}

.indicator-group-title {
    text-align: center;
}

.indicator-group-create-survey {
    text-align: center;
}

.indicator-headers {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.indicator-subgroups-create-survey {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.subgroup-type-header {
  text-transform: capitalize;
}

.subgroup-create-survey {
  &:first-of-type {
    margin-right: 4rem;
  }

  &:last-of-type {
    margin-right: 0rem;
  }

  p {
    &:first-child {
      font-weight: bold;
      font-size: 1.3rem;
      color: $contentGrey;
    }
  }

  &.other {
    background-color: transparent;
  }
  background-color: #eaebee;
  padding: 2rem;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
