$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

// Breadcrumbs
$breadcrumb-item-padding: 0.5rem !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote('>') !default;
$breadcrumb-border-radius: $border-radius !default;

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $breadcrumb-border-radius;
}

.breadcrumb-item {
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  &:nth-of-type(2)::before,
  &:nth-of-type(3)::before {
    content: '>' !important;
  }

  a {
    color: $gray-900;
    text-decoration: underline;
  }

  + .breadcrumb-item {
    padding-left: $breadcrumb-item-padding;

    &::before {
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-right: $breadcrumb-item-padding;
      color: $breadcrumb-divider-color;
      content: $breadcrumb-divider;
    }
  }

  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  // stylelint-disable-next-line no-duplicate-selectors
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: $breadcrumb-active-color;
  }
}
