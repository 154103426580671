@charset "UTF-8";
.mb-6 {
  margin-bottom: 3.5rem !important; }

@keyframes fadeIn {
  from {
    opacity: 0;
    left: 35px; }
  to {
    opacity: 1;
    left: 0; } }

.hidden {
  display: none; }

.opacity-hidden {
  opacity: 0 !important;
  animation: none !important; }

.flex-row {
  display: flex;
  flex-direction: row; }

.blue-fill-icon {
  fill: #2d809f !important;
  cursor: pointer; }
  .blue-fill-icon:first-of-type {
    margin-right: 1rem; }

.pointer-events-none {
  pointer-events: none; }

.sibling-border-top + .sibling-border-top {
  border-top: 1px solid #eaeaea;
  padding-top: 1rem; }

.flex-center {
  display: flex;
  justify-content: center; }

.clickable {
  cursor: pointer; }

.justify-content-flex-end {
  justify-content: flex-end; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #f0f1f5; }

html,
body {
  height: 100%;
  width: 100%; }

.error-text {
  font-weight: bold;
  color: #f00; }

a {
  color: #1E6986;
  transition: all 0.2s ease-out; }
  a:hover {
    color: #3393b9; }
  a.up-arrow {
    font-weight: bold;
    margin-left: 8px;
    position: relative; }
    a.up-arrow:before {
      background-image: url("../../assets/imgs/up-arrow.png");
      content: '';
      height: 19px;
      left: -28px;
      position: absolute;
      width: 19px; }

p {
  font-size: 0.95rem;
  line-height: 1.7rem;
  margin-bottom: 1.7rem; }

h1 {
  font-size: 24px; }

h2 {
  font-size: 18px; }

.content ol, .content ul {
  margin-bottom: 30px; }
  .content ol li, .content ul li {
    margin-bottom: 0.8rem; }
  .content ol a li, .content ul a li {
    font-weight: 500; }

.zmdi {
  color: #11526a;
  font-size: 22px;
  margin-right: 10px;
  position: relative;
  top: 1px; }

.no-list-style {
  list-style: none; }

.caption {
  font-size: 0.85rem;
  color: #727272; }

.header {
  border-top: 5px solid #11526a;
  box-sizing: border-box;
  margin-bottom: 40px;
  max-height: 75px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px; }
  .header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .header .logo {
    flex-grow: 0;
    flex-shrink: 0;
    width: auto;
    height: 75px; }
  .header .user {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    font-size: 0.8rem;
    line-height: 0.98rem; }
    .header .user .username {
      font-weight: bold;
      color: #3c3c3c; }
    .header .user .role {
      color: rgba(0, 0, 0, 0.7);
      font-style: italic; }
    .header .user a {
      color: #11526a;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer; }
    .header .user .avatar {
      height: 50px;
      width: 50px;
      margin-left: 20px; }

.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.125rem;
  min-height: 50px; }
  .sub-header button:last-child {
    margin-right: 0; }

footer .smallfooter {
  width: 100%;
  background-color: white;
  height: 100px;
  display: flex;
  flex-flow: row;
  align-content: center; }
  @media screen and (min-width: 768px) {
    footer .smallfooter {
      display: none; } }
  footer .smallfooter img {
    margin: auto 7px;
    width: 50px;
    height: 50px; }
    @media screen and (max-width: 768px) {
      footer .smallfooter img {
        display: none; } }
  footer .smallfooter div {
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: space-around;
    margin: 5px 0; }
    footer .smallfooter div h6,
    footer .smallfooter div p {
      font-size: 16px;
      margin: 0; }

footer .footerUserInfo {
  bottom: 0;
  right: 50px;
  height: 100px;
  position: absolute;
  z-index: 1; }
  footer .footerUserInfo ul {
    list-style-type: none;
    text-align: left;
    justify-content: space-between;
    flex-flow: column; }
    footer .footerUserInfo ul li a {
      color: #11526a; }
      footer .footerUserInfo ul li a img {
        width: 15px;
        height: 15px;
        margin-right: 10px; }
      footer .footerUserInfo ul li a:visited {
        color: #11526a; }

footer .largefooter {
  position: relative;
  margin-top: 250px;
  bottom: 0; }
  footer .largefooter .right {
    height: 30vw;
    max-height: 175px;
    position: absolute;
    width: 100%;
    bottom: 0;
    pointer-events: none; }
    @media screen and (max-width: 768px) {
      footer .largefooter .right {
        display: none; } }
    @media screen and (min-width: 992px) {
      footer .largefooter .right {
        height: 175px;
        bottom: 0; } }
  footer .largefooter .left {
    position: absolute;
    width: 30vw;
    height: 15vw;
    background: white;
    bottom: 0;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      footer .largefooter .left {
        display: none; } }
    @media screen and (min-width: 992px) {
      footer .largefooter .left {
        max-height: 150px; } }
    footer .largefooter .left .content {
      width: 100%;
      position: relative;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-content: center;
      margin: 45px auto 0; }
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        footer .largefooter .left .content {
          transform: scale(0.8); } }
      @media screen and (max-width: 992px) {
        footer .largefooter .left .content {
          transform: scale(0.7); } }
      footer .largefooter .left .content img {
        margin-right: 20px;
        width: 55px;
        height: 55px; }
      footer .largefooter .left .content div h6,
      footer .largefooter .left .content div p {
        font-size: 16px;
        margin: 0;
        white-space: nowrap; }

footer .left:before {
  content: '';
  position: absolute;
  border-left: 30.1vw solid transparent;
  border-bottom: 20px solid white;
  border-right: 15px solid transparent;
  transform: skew(-36.5deg);
  top: -20px; }

footer .left:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-right: 25px solid transparent;
  border-bottom: calc(15vw + 20px) solid white;
  top: -20px;
  right: -25px; }
  @media screen and (min-width: 992px) {
    footer .left:after {
      border-bottom: 170px solid white; } }

.tm-layout > .container,
.tm-layout header > .container {
  max-width: 1400px;
  min-height: calc(100vh - 360px); }

.survey-container main {
  min-height: calc(100vh - 360px); }

[data-role="district_champion"] .header {
  border-top-color: #1d5a1d; }

[data-role="district_champion"] .themed-fill {
  fill: #56af56 !important; }
  [data-role="district_champion"] .themed-fill.muted {
    fill: #1d5a1d !important; }

[data-role="district_champion"] .themed-stroke {
  stroke: #56af56 !important; }
  [data-role="district_champion"] .themed-stroke.muted {
    stroke: #1d5a1d !important; }

[data-role="school_champion"] .header {
  border-top-color: #584649; }

[data-role="school_champion"] .themed-fill {
  fill: #9B5865 !important; }
  [data-role="school_champion"] .themed-fill.muted {
    fill: #584649 !important; }

[data-role="school_champion"] .themed-stroke {
  stroke: #9B5865 !important; }
  [data-role="school_champion"] .themed-stroke.muted {
    stroke: #584649 !important; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item:nth-of-type(2)::before, .breadcrumb-item:nth-of-type(3)::before {
  content: '>' !important; }

.breadcrumb-item a {
  color: #212529;
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: ">"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.backdrop {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999; }
  .backdrop .spinner {
    font-size: 48px; }

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 30px;
  width: 600px;
  max-height: 600px; }

.headingContainer {
  color: #3c3c3c;
  max-width: 500px;
  text-align: left;
  font-size: 16px;
  line-height: 22px; }

.logo {
  margin-bottom: 50px;
  width: 400px; }

.subhead {
  font-size: 14px; }

.container-demo-questions {
  animation: fadeIn 0.3s ease-out 1 forwards;
  max-width: 600px;
  width: 80%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  position: relative;
  left: 0;
  min-height: 500px;
  opacity: 1; }
  .container-demo-questions .answerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 40px; }
  .container-demo-questions .radioGroup label {
    width: 50%; }
  .container-demo-questions .head {
    font-weight: bold;
    font-size: 18pt;
    margin-top: 0;
    margin-bottom: 0; }
  .container-demo-questions .heading {
    text-align: left;
    width: 100%; }
  .container-demo-questions .leftColumn,
  .container-demo-questions .rightColumn {
    width: 50%; }

.container-participant-confirmation {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 20%;
  margin-top: 4rem; }

.gradeLine {
  margin-top: 2vh;
  margin-bottom: 2vh; }

.head {
  font-weight: bold;
  font-size: 18pt;
  margin-top: 0;
  margin-bottom: 0; }

.heading {
  text-align: left;
  width: 100%; }

.studentInformation {
  font-size: 20px;
  line-height: 15px;
  text-align: left;
  margin-top: 40px;
  width: 100%; }
  span .studentInformation div:first-of-type {
    font-weight: bold; }

.skinny {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px; }

.fat {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%; }

.quiz-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  min-height: 64px;
  border-radius: 12px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  font-weight: bold;
  color: #727272;
  padding: 10px 0;
  margin: 8px 0; }
  .quiz-button:active {
    box-shadow: none; }
  .quiz-button:hover {
    color: #f8f9fd;
    background-color: #11526a; }
    .quiz-button:hover .keyboard-indicator-styles {
      background-color: #2d809f;
      border: none;
      box-shadow: 0px 2px 0px 0px #11526a; }
  .quiz-button:focus {
    outline: none;
    box-shadow: 0 0 0 0.3rem rgba(51, 147, 185, 0.25); }
  .quiz-button.fat {
    width: 49.6%;
    height: 150px;
    justify-content: center;
    align-items: center;
    margin: 10px; }
    .quiz-button.fat .qb-text {
      margin: 0 auto;
      display: block;
      text-align: center;
      font-size: 30px; }
    .quiz-button.fat .keyboard-indicator-styles {
      position: absolute;
      right: 10px;
      bottom: 10px; }
    .quiz-button.fat.has-audio {
      margin-bottom: 2.5rem; }
  .quiz-button.skinny {
    border-radius: 5px;
    justify-content: space-between;
    flex-flow: row;
    flex-direction: row;
    width: 100%; }
    .quiz-button.skinny .qb-text {
      font-size: 13pt;
      font-weight: bold;
      margin: 0 0 0 37px;
      display: inline-block;
      float: left; }
    .quiz-button.skinny .keyboard-indicator-styles {
      margin: 0 27px 0 0;
      display: inline-flex;
      float: right; }

.clicked {
  background-color: #2d809f;
  color: #f8f9fd; }
  .clicked .keyboard-indicator-styles {
    background-color: #3393b9;
    border: none;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.1); }

.keyboard-indicator-styles {
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.15);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  font-size: 10pt; }

.page-container-survey-access {
  margin-top: 4%; }
  .page-container-survey-access .form-control.input.v2 {
    text-transform: uppercase; }

.page-container-survey-complete {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 75vh; }

.page-container-survey-intro {
  margin-top: 4%; }

.container-all-questions {
  width: 80%;
  margin: 2rem auto 0; }
  .container-all-questions .answerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 40px; }

.centerText {
  text-align: center !important;
  margin-bottom: 20px; }

.container-single-question {
  animation: fadeIn 0.3s ease-out 1 forwards;
  opacity: 1;
  margin: 0 auto;
  min-height: 600px;
  min-width: 50vw;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 0; }
  .container-single-question:focus {
    outline: none; }
  .container-single-question .subhead {
    margin-bottom: 5px; }

.hidden {
  display: none; }

.head {
  font-weight: bold;
  font-size: 18pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 35px; }

.heading {
  text-align: left;
  width: 100%; }

.justifyCenter {
  justify-content: center; }

.progressBar {
  margin-bottom: 50px;
  margin-top: 50px; }

.react-sweet-progress-line {
  height: 20px; }

.react-sweet-progress-line-inner {
  height: 20px; }

.react-sweet-progress-symbol {
  white-space: nowrap; }

.alert {
  margin-top: 1rem;
  color: white !important;
  background-color: #3393b9 !important;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem !important; }

.alertHeader {
  font-weight: bold;
  font-size: 1.2rem; }

.breadcrumbButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline; }

.breadcrumbLink {
  color: black;
  text-decoration: underline; }

.btnContainer {
  display: flex;
  margin-top: 1rem; }

.content {
  margin: 0 10px; }

.modalContent {
  padding: 2rem; }

.modalBtn {
  width: 12rem;
  border-radius: 2rem !important; }

.modalInput {
  width: 100%;
  background-color: #f3f4f1;
  padding: 15px;
  border: none;
  border-radius: 5px;
  margin: 4px 0 20px; }

.table [data-key=icon],
.table [data-key=star] {
  width: 1px;
  white-space: nowrap; }

.tableHeader {
  background-color: #11526a;
  color: #f8f9fd;
  font-size: 0.8rem; }

Table {
  background-color: white; }

.tmHeader {
  font-size: 0.8rem;
  color: grey; }

.small-blue-button {
  background-color: #3393b9 !important;
  height: 50px;
  font-size: 12pt;
  min-width: 10rem;
  color: white;
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: none;
  margin-right: 1rem; }
  .small-blue-button:hover {
    background-color: #2d809f !important;
    color: #f8f9fd;
    transition: background-color 300ms ease; }

.small-blue-button-outlined {
  background-color: transparent;
  height: 50px;
  font-size: 12pt;
  min-width: 10rem;
  color: #2d809f;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: 0.08rem solid #2d809f !important;
  margin-right: 1rem; }
  .small-blue-button-outlined:hover {
    background-color: #2d809f;
    color: #f8f9fd;
    transition: background-color 300ms ease; }

.small-red-button-outlined {
  background-color: transparent;
  height: 50px;
  font-size: 12pt;
  width: 10rem;
  color: red;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: 0.08rem solid red !important;
  margin-right: 1rem; }
  .small-red-button-outlined:hover {
    background-color: red;
    color: #f8f9fd;
    transition: background-color 300ms ease; }

.small-gray-button-outlined {
  background-color: transparent;
  height: 50px;
  font-size: 20pt;
  font-weight: bold;
  width: 9rem;
  color: gray;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: 0.08rem solid gray !important;
  margin-right: 1rem; }
  .small-gray-button-outlined:hover {
    background-color: transparent;
    color: #2d809f;
    transition: background-color 300ms ease;
    border: 0.08rem solid #2d809f !important;
    cursor: pointer; }

.button {
  border-radius: 50px;
  font-weight: bold;
  font-size: 13pt;
  cursor: pointer;
  border: none;
  margin-top: 25px; }
  .button:active {
    outline: none; }
  .button:focus {
    outline: none; }

.green {
  width: 300px;
  background-color: #56af56;
  color: white;
  transition: background-color 300ms ease;
  height: 75px; }
  .green:hover {
    background-color: #3e893e;
    color: white; }
  .green:hover > div > div > img {
    transform: translateX(10px); }
  .green div div img {
    transition: transform 1s ease; }
  .green:focus {
    outline: none;
    box-shadow: 0 0 0 0.3rem rgba(62, 137, 62, 0.25); }
  .green[disabled] {
    background-color: #56af56; }
    .green[disabled]:hover {
      background-color: #56af56; }

.disabled,
[disabled] {
  opacity: 0.5;
  cursor: no-drop; }
  .disabled:hover > div > img,
  [disabled]:hover > div > img {
    transform: none; }

.xtra-small-button {
  height: 30px;
  font-size: 12pt;
  line-height: 12pt;
  width: auto;
  min-width: 6rem;
  color: white;
  background-color: #56af56;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: none;
  margin-right: 1rem; }

.small-button {
  height: 50px;
  font-size: 12pt;
  width: auto;
  min-width: 10rem;
  color: white;
  background-color: #56af56;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: none;
  margin-right: 1rem; }

.small-button-toggle {
  height: 50px;
  font-size: 12pt;
  width: 10rem;
  color: white;
  background-color: #bcbcbc;
  transition: background-color 300ms ease;
  border-radius: 3rem;
  border: none;
  margin-right: 1rem; }
  .small-button-toggle:hover {
    background-color: #56af56 !important; }
  .small-button-toggle.active {
    background-color: #56af56 !important; }

.nav-buttons .col {
  padding-left: 5px;
  padding-right: 5px; }
  .nav-buttons .col:first-child {
    padding-left: 15px; }
  .nav-buttons .col:last-child {
    padding-right: 15px; }

.nav {
  background-color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem 16px;
  outline: none;
  min-height: 300px;
  width: 100%;
  transition: all 0.1s ease-in-out; }
  .nav:active {
    box-shadow: none; }
  .nav:hover {
    background-color: #3393b9;
    color: white; }
  .nav:disabled {
    opacity: 0.3; }
    .nav:disabled:hover {
      background-color: white;
      color: rgba(0, 0, 0, 0.6); }
    .nav:disabled:active {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); }
  .nav div {
    text-align: center;
    margin: 0 auto;
    justify-content: center; }
  .nav .icon {
    width: 30%;
    margin-bottom: 20px; }
    .nav .icon svg {
      max-width: 100px;
      max-height: 100px; }
  .nav .desc {
    font-size: 20px;
    line-height: 22px; }
  .nav .mainText {
    font-size: 22px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 4px;
    text-transform: uppercase; }

.button-text {
  background: transparent;
  border: none;
  color: #2d809f;
  cursor: pointer; }
  .button-text.no-link {
    color: inherit; }
    .button-text.no-link:hover {
      text-decoration: none; }

.forgot {
  color: black;
  margin-top: 4vh; }

.logo-login {
  margin-bottom: 20px;
  width: 300px; }

.clever-logo-login {
  margin-top: 20px;
  width: 300px; }

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  padding: 60px;
  min-width: 300px;
  max-width: 600px;
  min-height: 400px;
  margin: 30px auto; }

.error {
  margin: 10px 0;
  display: block;
  font-size: 15px;
  font-style: italic;
  color: #f00; }

.modalInput + .error {
  margin: -10px 0 10px; }

.forgotPassword {
  font-size: 14px;
  color: gray;
  color: #11526a;
  margin-top: 30px;
  border: none; }

.form-control {
  background-color: #f3f4f1;
  height: 50px;
  border: none;
  border-radius: 10px;
  margin: 20px 0; }
  .form-control:focus {
    outline: none;
    box-shadow: none;
    background-color: #f3f4f1; }
  .form-control.has-error {
    border: solid 1px #b93333; }

.input::placeholder {
  color: #bababa; }

.label {
  margin-bottom: 0;
  font-weight: bold; }

.change-password .breadcrumb,
.reset-password .breadcrumb {
  padding-top: 0; }

.change-password .form-group,
.reset-password .form-group {
  align-items: flex-start;
  width: auto;
  margin-right: 0; }

.react-tel-input input[type=text],
.react-tel-input input[type=tel] {
  height: 50px !important;
  width: 100% !important; }
  .react-tel-input input[type=text].participant-entry-field,
  .react-tel-input input[type=tel].participant-entry-field {
    border: none; }

.react-tel-input .participant-entry-field + .flag-dropdown {
  border: none; }

.container-radio-btn {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  display: row;
  align-items: center; }
  .container-radio-btn input {
    margin-right: 12px; }
  .container-radio-btn.has-audio {
    position: relative;
    margin-bottom: 2.5rem; }

.description {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2vw;
  text-align: left; }

.radioGroup {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between; }

.breadcrumb-create-survey {
  background-color: #ff000000;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-inline-start: 0; }

.pointer {
  cursor: pointer; }

.bold-normal {
  font-weight: 400; }

.breadcrumb-info-container p {
  margin-top: 0;
  margin-bottom: 0rem;
  font-size: 0.8rem;
  color: grey; }

.dob-container-participant-entry {
  display: flex;
  align-items: flex-end;
  margin-left: 1rem; }

.rw-i-caret-down:before {
  content: '\E803';
  color: #2d809f; }

.rw-widget-input, .participant-entry-field {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border: none;
  border-radius: 0.4rem;
  box-shadow: 0px 1px 2px 0px rgba(150, 150, 150, 0.75); }

.tm-dropdown {
  margin-right: 2rem;
  width: 13rem !important; }

.tm-dropdown.short {
  width: 8rem !important; }

.tm-dropdown.wide {
  width: 18rem !important; }

@media (min-width: 576px) {
  .modal.edit-participant-modal-wrapper .modal-dialog {
    max-width: 700px;
    width: 700px; } }

@media (min-width: 576px) {
  .modal.add-participant-modal-wrapper .modal-dialog {
    max-width: 820px;
    width: 820px; } }

.participant-row {
  display: flex;
  align-items: flex-end; }

.label-field-container {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  min-width: 0;
  flex-basis: auto;
  flex-grow: 2; }
  .label-field-container.phone {
    max-width: 146px; }
  .label-field-container.icon {
    max-width: 48px; }
  .label-field-container:last-child {
    margin-right: 0; }
  .label-field-container .tm-dropdown.short {
    margin-right: 0 !important;
    width: auto !important; }

.label-background-color {
  width: auto;
  border-radius: 10px; }

.uk-date-order {
  display: flex; }
  .uk-date-order .uk-date-order-0 {
    order: 0; }
  .uk-date-order .uk-date-order-1 {
    order: 1; }
  .uk-date-order .uk-date-order-2 {
    order: 2; }

.form-container-create-survey {
  display: flex;
  flex-direction: row;
  margin-left: 5rem; }
  .form-container-create-survey .dropdown-container .dropdown-label {
    font-weight: bold; }
  .form-container-create-survey .dropdown-container .rw-dropdown-list {
    width: 150px; }

.form-group {
  margin-right: 1rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center; }

.participant-entry-field {
  padding-left: 1rem;
  height: 3rem; }

.participant-entry-field.long {
  width: 20rem; }

.participant-entry-field.short {
  width: 8rem; }

.tag {
  margin-top: 1rem; }

.tag-item {
  background-color: #bcbcbc;
  display: inline-block;
  font-size: 14px;
  border-radius: .4rem;
  height: 30px;
  padding: 0 5px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0.1rem 0.3rem 0.3rem 0; }

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: .4rem;
  border: none;
  cursor: pointer;
  font: inherit;
  margin: 0 0 0 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center; }

.step-header {
  display: flex;
  align-items: center; }
  .step-header .step-number {
    font-size: 6rem;
    font-weight: bold;
    margin-right: 2rem;
    margin-bottom: 0; }
  .step-header div p:first-of-type {
    font-weight: bold;
    margin-bottom: 0; }

.survey-margin-left {
  margin-left: 5rem; }

.survey-step-container {
  margin-top: 100px; }
  .survey-step-container:not(:last-child):after {
    content: '';
    display: block;
    border: none;
    color: white;
    height: 1px;
    background: black;
    background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 600, from(#bcbcbc), to(#fff0));
    margin-top: 100px; }
  .survey-step-container:last-child {
    padding-bottom: 150px; }
  .survey-step-container[data-active=false] {
    pointer-events: none;
    opacity: 0.5; }

.clever-student-selection-wrapper {
  max-width: 600px;
  background: #eaeaea;
  padding: 25px; }

.clever-all-grades-wrapper {
  margin-bottom: 15px; }

.clever-student-selection {
  display: flex;
  flex-wrap: wrap; }
  .clever-student-selection > div {
    flex-basis: 25%;
    margin-bottom: 15px; }

.bottom-border {
  display: block;
  border: none;
  color: white;
  height: 1px;
  background: black;
  background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 600, from(#bcbcbc), to(#fff0));
  margin-top: 4rem; }

.button-container-indicator-select {
  margin-left: 5rem;
  margin-bottom: 4rem; }

.content-indicator-select-form {
  display: none;
  flex-direction: row;
  justify-content: space-evenly; }

.indicator-group-title {
  text-align: center; }

.indicator-group-create-survey {
  text-align: center; }

.indicator-headers {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 2rem; }

.indicator-subgroups-create-survey {
  display: flex;
  flex-direction: row;
  text-align: left; }

.subgroup-type-header {
  text-transform: capitalize; }

.subgroup-create-survey {
  background-color: #eaebee;
  padding: 2rem;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .subgroup-create-survey:first-of-type {
    margin-right: 4rem; }
  .subgroup-create-survey:last-of-type {
    margin-right: 0rem; }
  .subgroup-create-survey p:first-child {
    font-weight: bold;
    font-size: 1.3rem;
    color: #727272; }
  .subgroup-create-survey.other {
    background-color: transparent; }

input[type=checkbox] {
  display: none; }

.checkbox {
  margin-right: 1rem; }

.checkbox {
  display: block;
  cursor: pointer;
  position: relative; }

.modal-content {
  border: none;
  border-radius: 0.5rem; }
  .modal-content .modal-header {
    border-bottom: none;
    padding: 2rem 2rem 1.5rem; }
  .modal-content .modal-title {
    line-height: 1;
    display: flex;
    align-items: flex-end; }
  .modal-content .modalContent {
    padding: 0 2rem 2rem; }

.btnContainer .btn:last-child {
  margin-right: 0; }

.btnContainer-edit-participant {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem; }
  .btnContainer-edit-participant button:first-of-type {
    margin-right: 1rem; }

.btnContainer-bulk-notify {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  color: red; }
  .btnContainer-bulk-notify button:first-of-type {
    margin-right: 1rem; }

.add-student-button {
  margin-right: 0.5rem;
  cursor: pointer; }

.custom-file {
  position: relative; }
  .custom-file .error {
    position: absolute;
    top: 100%; }

.custom-file-label {
  border-radius: 0.25rem 1.25rem 1.25rem 0.25rem !important; }

.custom-file-label::after {
  width: 8rem;
  text-align: center;
  color: white;
  border-radius: 0 1.25rem 1.25rem 0 !important;
  background-color: #3393b9; }

.file-input-create-survey-step-group {
  display: flex;
  margin-left: 5rem;
  align-items: center; }
  .file-input-create-survey-step-group .input-group {
    width: 24rem;
    margin-left: 2rem; }

.email-recipient-step {
  display: flex;
  flex-direction: column; }

.manual-student {
  margin-left: 5rem;
  margin-top: 2rem; }

.manual-student-header {
  font-weight: bold;
  margin-bottom: 0; }

.manual-student-subhead {
  margin-bottom: 3rem; }

.header-container-survey-list {
  display: flex;
  margin-top: 4rem; }

.survey-list-button-header-container {
  margin-left: auto; }

.container-search-survey {
  margin-bottom: 2rem; }

.input-container-search-survey {
  display: flex;
  width: 68rem;
  flex-wrap: wrap; }

.label {
  margin-top: 1rem;
  display: block; }

.search-survey-header {
  font-weight: bold;
  font-size: 2rem; }

.search-survey-input {
  border: none;
  color: #333;
  font-size: 1em;
  font-family: inherit;
  outline: none;
  height: 3rem;
  width: 18rem;
  padding-left: 1rem;
  border-radius: 0.4rem;
  box-shadow: 0px 1px 2px 0px rgba(150, 150, 150, 0.7); }

.search-survey-submit-container {
  margin-top: 2rem; }

.footnote-text-edit-participant {
  font-size: 0.7rem; }
  .footnote-text-edit-participant:first-of-type {
    margin-top: 1rem; }

.header-survey-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }
  .header-survey-details:last-of-type {
    margin-bottom: 1rem; }

.modal-header-edit-participant {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.student-results-label-edit-participant {
  font-size: 0.9rem; }

.survey-info div:first-child {
  margin-right: 10rem; }

.context-menu {
  margin-top: 14px;
  display: inline-flex;
  line-height: 5px;
  border: 1px solid black;
  border-radius: 3rem;
  width: 3rem;
  height: 20px;
  text-align: center;
  padding-left: 11px; }

.dropdown {
  position: relative;
  float: right; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
  z-index: 1;
  right: 0;
  float: right; }

.dropdown-content span {
  color: #2d809f;
  padding: 8px 5px;
  font-size: 1rem;
  text-decoration: none;
  display: block; }

.dropdown-content span:hover {
  background-color: #ddd; }

.dropdown:hover .dropdown-content {
  display: block;
  float: right; }

.grade-label {
  color: gray;
  padding: 0;
  font-size: .9rem; }

@media print {
  header,
  footer,
  .tableHeader,
  .create-survey-container .table td:nth-child(n+5) {
    display: none !important; }
  .create-survey-container .table td {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .create-survey-container .table td:nth-child(2):before {
    content: 'Grade:';
    margin-right: 0.5rem; }
  .create-survey-container .table td:nth-child(3):before {
    content: 'Date of Birth:';
    margin-right: 0.5rem; }
  .create-survey-container .table td:nth-child(4):before {
    content: 'Login:';
    margin-right: 0.5rem; } }

.report-link {
  display: inline-block; }
  .report-link .report-icon {
    display: block;
    position: relative; }
    .report-link .report-icon:before, .report-link .report-icon:after {
      position: absolute;
      display: block; }
    .report-link .report-icon[data-contacted=true]:before {
      content: '✓';
      top: 8px;
      right: -22px;
      height: 13px;
      width: 17px;
      font-size: 22px;
      font-weight: 700;
      line-height: 0.65;
      color: #56af56; }
    .report-link .report-icon[data-num]:after {
      content: attr(data-num);
      border: 4px solid white;
      border-radius: 50%;
      right: -9px;
      bottom: -4px;
      z-index: 1;
      height: 22px;
      width: 22px;
      color: white;
      font-weight: 900;
      font-size: 11px;
      text-align: center; }
    .report-link .report-icon[data-num='1']:after, .report-link .report-icon[data-num='2']:after {
      background-color: #b93333; }
    .report-link .report-icon[data-num='3']:after, .report-link .report-icon[data-num='4']:after, .report-link .report-icon[data-num='5']:after {
      background-color: #d9c363; }

.statusCountText {
  color: #3c3c3c;
  float: right;
  font-size: 0.85rem; }

.modal-table-container {
  height: 300px;
  overflow-y: scroll; }

.select-all-invite {
  align-items: center;
  display: flex;
  justify-content: left; }

.surveyDetailsDropDownButton {
  background-color: #56af56;
  border: #56af56; }

.surveyDetailsDropDownButton:hover, .surveyDetailsDropDownButton:focus, .surveyDetailsDropDownButton:active {
  background-color: #1d5a1d;
  border: #1d5a1d; }

.container-search-reports {
  margin-bottom: 2rem; }

.input-container-search-reports {
  display: flex;
  width: 70rem;
  flex-wrap: wrap; }

.radio-container-search-reports {
  margin-right: 2rem !important; }

.radio-search-reports {
  margin-top: 1rem; }
  .radio-search-reports div {
    margin-right: 1rem;
    align-items: baseline; }
    .radio-search-reports div input {
      margin-right: 1rem; }

.toast.tm {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 8px;
  margin: 0;
  padding: 1.5rem 1.75rem;
  min-width: 250px;
  background-color: #3393b9;
  color: #fff; }
  .toast.tm .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    width: 2rem;
    font-weight: 100;
    text-shadow: none;
    color: #000; }
  .toast.tm .toast-body {
    padding: 0; }

/** Generic Card **/
.tm-card {
  background-color: #fff;
  max-width: 854px;
  width: 100%; }
  .tm-card .tm-card-header {
    padding: 26px; }
    .tm-card .tm-card-header p {
      font-size: 15px;
      line-height: 1.5rem; }
  .tm-card .tm-card-content {
    display: flex;
    flex-direction: column;
    padding: 56px; }
  .tm-card.tm-card-sm .tm-card-header {
    padding: 26px 26px 10px; }
  .tm-card.tm-card-sm .tm-card-content {
    padding: 10px 26px 26px; }
  .tm-card.tm-card-md .tm-card-header {
    padding: 56px 56px 10px; }
  .tm-card.tm-card-md .tm-card-content {
    padding: 10px 56px 56px; }
  .tm-card.tm-card-lg .tm-card-header {
    padding: 66px 66px 10px; }
  .tm-card.tm-card-lg .tm-card-content {
    padding: 10px 66px 66px; }
  .tm-card.tm-card-headerless.tm-card-sm {
    padding: 26px; }
  .tm-card.tm-card-headerless.tm-card-md {
    padding: 56px; }
  .tm-card.tm-card-headerless.tm-card-lg {
    padding: 66px; }

.tm-card.tm-card-stepper {
  margin-left: auto;
  margin-right: auto; }
  .tm-card.tm-card-stepper .tm-card-header {
    max-width: 600px;
    margin: 0 auto; }
    .tm-card.tm-card-stepper .tm-card-header img {
      max-width: 420px;
      width: 90%;
      min-width: 240px;
      margin-bottom: 30px; }
  .tm-card.tm-card-stepper .tm-card-content {
    max-width: 600px;
    margin: 0 auto; }
    .tm-card.tm-card-stepper .tm-card-content .form-group {
      width: 100%;
      max-width: 371px;
      margin: 0 auto; }
      .tm-card.tm-card-stepper .tm-card-content .form-group input {
        margin-bottom: 10px; }
    .tm-card.tm-card-stepper .tm-card-content button.tm-button {
      max-width: 300px;
      margin: 40px auto 10px; }

/**
Author bcorbin@fusionalliance.com
A generic button component that focsues on using Bootstrap classes.
**/
.tm-button {
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  /** Create a Green Buttonb **/
  /** Made Large Button Large **/
  /** Position an Icon on the Right **/
  /** Icon Left side **/ }
  .tm-button.btn-green {
    background-color: #56af56;
    border: none;
    color: #fff; }
    .tm-button.btn-green:hover {
      background-color: #4ca04c; }
  .tm-button.btn-lg {
    height: 70px;
    font-size: 17px; }
    .tm-button.btn-lg.btn-round {
      border-radius: 35px; }
  .tm-button .btn-icon-right {
    transition: all 0.75s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 40px;
    top: 0;
    bottom: 0; }
  .tm-button .btn-icon-left {
    transition: all 0.75s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 40px;
    top: 0;
    bottom: 0; }
  .tm-button:hover .btn-icon-right {
    transition: all 0.75s ease-in-out;
    right: 20px; }
  .tm-button:hover .btn-icon-left {
    transition: all 0.75s ease-in-out;
    left: 20px; }
  .tm-button.w-300 {
    width: 300px; }

.language-picker {
  display: block;
  font-size: 14px;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: 5px 40px 6px 12px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 2em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.871%22%20height%3D%228.352%22%20viewBox%3D%220%200%2012.871%208.352%22%3E%3Cpath%20d%3D%22M8.435%2C10.352%2C2%2C3.917%2C3.917%2C2%2C8.435%2C6.518%2C12.954%2C2l1.917%2C1.917Z%22%20transform%3D%22translate%28-2%20-2%29%22%20fill%3D%22%232d809f%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(90deg, rgba(0, 0, 0, 0) calc(80% - 1px), #969696 calc(80%), rgba(0, 0, 0, 0) calc(80% + 1px));
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, right 30px;
  background-size: 9px auto, 100%; }
  .language-picker:hover {
    border-color: #888; }
  .language-picker:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none; }
  .language-picker:disabled, .language-picker[aria-disabled=true] {
    background-color: #eee; }
  .language-picker::-ms-expand {
    display: none; }
  .language-picker option {
    font-weight: normal; }

.audio-button {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  padding: 3px 12px 4px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #3393b9;
  border-radius: 2em;
  background-color: transparent; }
  .audio-button:hover {
    background-color: #fff; }
  .audio-button:focus {
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none; }
  .audio-button svg {
    fill: #3393b9;
    vertical-align: bottom; }
  .audio-button span {
    vertical-align: bottom;
    color: #3393b9; }
  .audio-button.has-label {
    background-image: linear-gradient(to right, #3393b9 0, #3393b9 40px, rgba(0, 0, 0, 0) 40px); }
    .audio-button.has-label svg {
      margin-right: 16px;
      fill: #f8f9fd; }
  .audio-button.small {
    height: 1.25em;
    line-height: 0;
    padding: 1px 5px; }
    .audio-button.small svg {
      height: 0.85em; }

.quiz-button .audio-button {
  position: absolute; }

.quiz-button.fat .audio-button {
  left: 0;
  bottom: -40px; }

.quiz-button.skinny .audio-button {
  right: -70px; }

.container-radio-btn .audio-button {
  position: absolute;
  top: 110%;
  left: 26px; }

.timegate-header {
  font-size: 18px;
  font-weight: bold; }

.timegate-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .timegate-wrapper .timegate-label {
    font-weight: bold;
    margin-right: 15px; }
    .timegate-wrapper .timegate-label p {
      margin-bottom: 0; }
  .timegate-wrapper .rw-widget-input, .timegate-wrapper .participant-entry-field {
    display: none; }
  .timegate-wrapper .rw-datetime-picker {
    max-width: 300px; }
  .timegate-wrapper .rw-popup {
    min-width: 125px; }
  .timegate-wrapper .timegate-component {
    display: flex; }
    .timegate-wrapper .timegate-component .timegate-component-input {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      color: #333;
      background: #FFFFFF;
      border: #ccc 1px solid; }

.App {
  text-align: center; }

.appLogo {
  animation: appLogoSpin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.appHeader {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.appLink {
  color: #61dafb; }

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.divider {
  display: none; }
  @media screen and (min-width: 480px) and (max-width: 1200px) {
    .divider {
      display: block; } }

.search-icon {
  position: absolute;
  top: 19px;
  right: -3px; }

.search-icon-container {
  position: relative;
  width: max-content;
  height: max-content; }

.pageName {
  font-weight: bold;
  font-size: 18px; }

.auth {
  font-size: 12px;
  color: gray; }

.templateLink {
  white-space: nowrap; }
  .templateLink a {
    color: #11526a;
    font-weight: 600; }
  .templateLink .fake-link {
    color: #11526a;
    font-weight: 600;
    cursor: pointer; }
    .templateLink .fake-link:hover {
      text-decoration: underline; }

.list-title {
  font-size: 30px; }

.colTitle {
  font-weight: 700;
  font-size: 20px; }

@media print {
  body {
    background-color: #fff;
    padding: 0 0 50px; }
    body .intro-hero.mb-5 {
      margin-bottom: 1rem !important; }
    body .tm-report header {
      padding: 1rem; }
      body .tm-report header .print-logo {
        margin: 1rem 0 0 auto;
        width: auto; }
    body .tm-report .page,
    body .tm-report.tm-report-parent .page {
      padding: 50px;
      page-break-inside: avoid; }
      body .tm-report .page:last-of-type,
      body .tm-report.tm-report-parent .page:last-of-type {
        padding-bottom: 0; }
      body .tm-report .page p,
      body .tm-report.tm-report-parent .page p {
        line-height: 1.5; }
  .page-break {
    page-break-after: always; }
  .print-block {
    page-break-inside: avoid;
    padding: 1rem 0; }
  .image-fit {
    max-height: 145px;
    object-fit: cover; }
  .col-print-1,
  .col-print-2,
  .col-print-3,
  .col-print-4,
  .col-print-5,
  .col-print-6,
  .col-print-7,
  .col-print-8,
  .col-print-9,
  .col-print-10,
  .col-print-11,
  .col-print-12 {
    float: left !important; }
  .col-print-12 {
    width: 100% !important; }
  .col-print-11 {
    width: 91.66666667% !important; }
  .col-print-10 {
    width: 83.33333333% !important; }
  .col-print-9 {
    width: 75% !important; }
  .col-print-8 {
    width: 66.66666667% !important; }
  .col-print-7 {
    width: 58.33333333% !important; }
  .col-print-6 {
    width: 50% !important; }
  .col-print-5 {
    width: 41.66666667% !important; }
  .col-print-4 {
    width: 33.33333333% !important; }
  .col-print-3 {
    width: 25% !important; }
  .col-print-2 {
    width: 16.66666667% !important; }
  .col-print-1 {
    width: 8.33333333% !important; }
  .offset-print-11 {
    margin-left: 91.66666667% !important; }
  .offset-print-10 {
    margin-left: 83.33333333% !important; }
  .offset-print-9 {
    margin-left: 75% !important; }
  .offset-print-8 {
    margin-left: 66.66666667% !important; }
  .offset-print-7 {
    margin-left: 58.33333333% !important; }
  .offset-print-6 {
    margin-left: 50% !important; }
  .offset-print-5 {
    margin-left: 41.66666667% !important; }
  .offset-print-4 {
    margin-left: 33.33333333% !important; }
  .offset-print-3 {
    margin-left: 25% !important; }
  .offset-print-2 {
    margin-left: 16.66666667% !important; }
  .offset-print-1 {
    margin-left: 8.333333% !important; } }

@page {
  size: auto;
  /* auto is the initial value */
  margin: 0mm;
  /* this affects the margin in the printer settings */ }

.tm-report {
  background-color: #fff; }
  .tm-report h1,
  .tm-report h2,
  .tm-report h3,
  .tm-report h4,
  .tm-report h5,
  .tm-report h6,
  .tm-report label,
  .tm-report p {
    margin: 0;
    padding: 0; }
  .tm-report h2 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.03rem; }
  .tm-report h6 {
    font-size: 12px; }
  .tm-report p {
    font-size: 0.8rem; }
  .tm-report header {
    background-color: #eee;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between; }
    .tm-report header h1 {
      font-size: 1.4rem; }
      .tm-report header h1 span {
        font-weight: normal;
        font-size: 1rem; }
    .tm-report header section {
      display: flex;
      flex-direction: column;
      padding: 16px;
      justify-content: center; }
      .tm-report header section.priority {
        background-color: rgba(0, 0, 0, 0.12); }
        .tm-report header section.priority h1 {
          font-size: 1.7rem;
          font-weight: 300; }
      .tm-report header section.branding img {
        height: 30px; }
      .tm-report header section.branding .print {
        line-height: 1.2;
        height: 2rem;
        min-width: 6rem; }
  .tm-report .wellness-center-home-header {
    padding: 0 40px;
    max-width: 1280px;
    margin: 50px auto 0 auto; }
  .tm-report .accordion div.card:only-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: calc(0.25rem - 1px); }
  .tm-report .page {
    max-width: 1280px;
    margin: 0px auto;
    padding: 40px; }
    .tm-report.tm-report-parent .page {
      padding-left: 15px;
      padding-right: 15px; }
      @media only screen and (min-width: 768px) {
        .tm-report.tm-report-parent .page {
          padding-left: 40px;
          padding-right: 40px; } }
    .tm-report .page .page-footer {
      width: 100%; }
    .tm-report .page.page-parent-report {
      padding-top: 25px; }
    .tm-report .page.page-parent-report .indicator-suggestions {
      padding: 15px 5px 25px;
      font-size: 0.8rem; }
      .tm-report .page.page-parent-report .indicator-suggestions p,
      .tm-report .page.page-parent-report .indicator-suggestions li {
        font-size: 0.8rem; }
    .tm-report .page.page-report-results {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .tm-report .page.page-report-results main {
        flex-grow: 1;
        flex-shrink: 1;
        width: calc(70% - 50px); }
  .tm-report .report-font-size {
    font-size: 1rem; }
    .tm-report .report-font-size * {
      font-size: 1rem; }
  .tm-report .block h3 {
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: 700;
    border-bottom: solid 1px #fff;
    margin-bottom: 16px;
    padding-bottom: 10px;
    display: inline-block; }
  .tm-report .block p {
    font-size: 0.7rem;
    line-height: 0.9rem;
    margin-bottom: 10px; }
    .tm-report .block p:last-child {
      margin-bottom: 6px; }
  .tm-report .block.score {
    font-size: 1rem;
    padding: 4px 10px;
    vertical-align: middle; }
  .tm-report .justify-space-between {
    justify-content: space-between; }
  .tm-report .previous-score {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.4); }
    .tm-report .previous-score .block {
      border: none; }
    .tm-report .previous-score a {
      display: none !important; }
  .tm-report .table.results {
    font-size: 0.8rem; }
    .tm-report .table.results td {
      vertical-align: middle; }
    .tm-report .table.results th {
      background-color: #eee;
      text-transform: uppercase;
      font-size: 0.76rem;
      line-height: 1.1rem; }
    .tm-report .table.results .score {
      text-align: center;
      padding: 4px;
      font-size: 0.8rem;
      font-weight: bold;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      .tm-report .table.results .score a {
        height: 16px;
        line-height: 14px;
        padding: 0 6px;
        font-size: 0.5rem;
        margin-left: 6px;
        color: #fff;
        opacity: 1;
        transition: all 0.2s ease-in-out; }
        .tm-report .table.results .score a.show-link {
          font-size: inherit; }
    .tm-report .table.results.table-50-50 td,
    .tm-report .table.results.table-50-50 th {
      width: 50%; }
    .tm-report .table.results.table-1-3 td,
    .tm-report .table.results.table-1-3 th {
      width: calc(100% / 3); }
  .tm-report .tfs-bar {
    height: 70px;
    display: flex;
    padding: 0px;
    align-items: center;
    background-color: #eee; }
    .tm-report .tfs-bar label {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 16px;
      background-color: rgba(0, 0, 0, 0.1); }
    .tm-report .tfs-bar .value {
      flex-grow: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700; }
    .tm-report .tfs-bar.tfs-bar-previous {
      height: 35px; }
  .tm-report .page.questions-of-concern h1 {
    font-size: 26px; }
  .tm-report .concerns-container .answers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .tm-report .concerns-container .answers .qa {
      font-size: 0.8rem;
      margin-bottom: 18px;
      width: 50%; }
      .tm-report .concerns-container .answers .qa div {
        display: flex;
        flex-direction: row;
        padding: 3px 2px; }
        .tm-report .concerns-container .answers .qa div .label {
          display: inline-block;
          width: 100px; }
        .tm-report .concerns-container .answers .qa div .index {
          width: 20px;
          display: inline-block; }
  .tm-report .font-size-xs {
    font-size: 0.8rem; }
  .tm-report .font-size-sm {
    font-size: 0.9rem; }
  .tm-report .font-size-md {
    font-size: 1rem; }
  .tm-report .font-size-lg {
    font-size: 1.2rem; }
  .tm-report .font-size-xl {
    font-size: 1.4rem; }

.block.block-outline-red {
  border: solid 1px #e16c69;
  color: #e16c69; }

.block.block-red {
  background-color: #e16c69;
  color: #fff; }

.block.block-outline-grey {
  border: solid 1px #bcbcbc;
  color: #bcbcbc; }

.block.block-grey {
  background-color: #fff;
  color: #000; }

.block.block-outline-green {
  border: solid 1px #56af56;
  color: #56af56; }

.block.block-green {
  background-color: #56af56;
  color: #fff; }

.block.block-outline-light-green {
  border: solid 1px #a6c086;
  color: #a6c086; }

.block.block-light-green {
  background-color: #a6c086;
  color: #fff; }

.block.block-outline-yellow {
  border: solid 1px #d9c363;
  color: #d9c363; }

.block.block-yellow {
  background-color: #d9c363;
  color: #fff; }

.score a {
  display: block;
  color: #fff;
  text-decoration: underline;
  font-weight: bold; }
  .score a::after {
    content: '›';
    height: 1em;
    width: 1em;
    margin-left: 0.25em;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: inline-block;
    transition: all 0.2s ease-out;
    font-size: 15px;
    line-height: 1;
    font-weight: bold;
    text-decoration: none; }
  .score a:hover {
    text-decoration: none; }
    .score a:hover::after {
      transform: translate(4px, 0); }

.show-not-concerning-responses {
  align-self: center; }
  .show-not-concerning-responses .checkbox {
    display: inline-block; }

@media print {
  body {
    background-color: #fff;
    -webkit-print-color-adjust: exact; } }

@page {
  size: auto;
  /* auto is the initial value */
  margin: 0mm;
  /* this affects the margin in the printer settings */ }

/**
 * group specific
*/
.tm-report-group {
  background-color: #fff; }
  .tm-report-group header {
    height: auto;
    position: relative; }
    .tm-report-group header .summary {
      text-align: right; }
    .tm-report-group header .group-header h2 {
      font-size: 1.7rem; }
    .tm-report-group header .group-header h3 {
      font-size: 1.4rem; }
    .tm-report-group header .group-header .locations {
      font-size: 0.8rem; }
    .tm-report-group header .branding img {
      height: 50px;
      left: 30px;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
  .tm-report-group .page-footer {
    min-width: 100%;
    bottom: 10px;
    padding: 10px 24px;
    font-size: 0.8rem;
    color: #bcbcbc; }
    @media print {
      .tm-report-group .page-footer {
        position: fixed; } }
    @media screen {
      .tm-report-group .page-footer {
        position: relative; } }
  .tm-report-group .group-title .branding {
    height: 140px; }
    .tm-report-group .group-title .branding img {
      height: 70px; }
  .tm-report-group .box-comment {
    padding: 10px 16px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px; }
    .tm-report-group .box-comment p {
      font-size: 0.9rem; }
    .tm-report-group .box-comment p:last-child {
      margin-bottom: 0; }
    .tm-report-group .box-comment h3 {
      font-size: 1.2rem; }
  .tm-report-group .title-band {
    background-color: #00657e;
    color: #f8f9fd;
    height: 500px;
    position: relative; }
  .tm-report-group .title-band--summary {
    left: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .tm-report-group .title-copyright {
    background-color: #a5c48f;
    margin-top: 5px; }
  .tm-report-group .table-tm-dark-striped {
    font-size: 0.95rem;
    border-left: solid 1px rgba(0, 0, 0, 0.1);
    border-bottom: solid 1px rgba(0, 0, 0, 0.1); }
    .tm-report-group .table-tm-dark-striped thead tr th {
      padding: 20px 16px;
      -webkit-print-color-adjust: exact;
      background: rgba(0, 101, 126, 0.3) !important;
      vertical-align: middle;
      text-align: center;
      border-right: solid 1px rgba(0, 0, 0, 0.1); }
    .tm-report-group .table-tm-dark-striped tr td {
      vertical-align: middle;
      text-align: center;
      border-right: solid 1px rgba(0, 0, 0, 0.1);
      padding: 12px 16px; }
    .tm-report-group .table-tm-dark-striped tr:nth-child(odd) td {
      -webkit-print-color-adjust: exact;
      background-color: rgba(0, 101, 126, 0.12) !important; }
  .tm-report-group .title-date {
    background-color: #3e7985;
    margin-bottom: 5px; }
  .tm-report-group .title-box {
    color: #f8f9fd;
    height: 80px;
    position: relative;
    padding: 10px;
    padding-left: 25px; }
    .tm-report-group .title-box .vertical-center {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
  .tm-report-group .title-col {
    padding-right: 5px !important; }
  .tm-report-group .title-image {
    background-size: cover;
    height: 400px; }
  .tm-report-group .title-photos {
    margin-top: 5px; }
    .tm-report-group .title-photos > div {
      padding: 0; }
    .tm-report-group .title-photos img {
      max-width: 100%; }
  .tm-report-group .block h3 {
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: 700;
    border-bottom: solid 1px #fff;
    margin-bottom: 16px;
    padding-bottom: 10px;
    display: inline-block;
    text-transform: uppercase; }
  .tm-report-group .block p {
    font-size: 0.7rem;
    line-height: 0.9rem;
    margin-bottom: 10px; }
    .tm-report-group .block p:last-child {
      margin-bottom: 6px; }
  .tm-report-group .page {
    max-width: 1280px;
    margin: 0px auto;
    padding: 0 0 0 0;
    min-height: 100vh;
    border-top: solid 1px rgba(0, 0, 0, 0.2); }
    .tm-report-group .page:first-child {
      border-top: none; }
    @media print {
      .tm-report-group .page {
        border-top: none;
        page-break-after: always; } }
    @media screen {
      .tm-report-group .page {
        padding-bottom: 20px; } }
    .tm-report-group .page.padding {
      padding: 24px; }
    .tm-report-group .page .indicator-suggestions {
      margin: 0px auto;
      padding: 20px 5px 40px; }
      .tm-report-group .page .indicator-suggestions small {
        display: block;
        font-size: 15px;
        margin-top: 10px; }
  .tm-report-group table.table td.col-red,
  .tm-report-group table.table th.col-red {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
    -webkit-print-color-adjust: exact;
    background-color: #b93333 !important;
    color: #fff !important; }
  .tm-report-group table.table td.col-yellow,
  .tm-report-group table.table th.col-yellow {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
    -webkit-print-color-adjust: exact;
    background-color: #d9c363 !important;
    color: #fff !important; }
  .tm-report-group table.table td.col-light-green,
  .tm-report-group table.table th.col-light-green {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
    -webkit-print-color-adjust: exact;
    background-color: #a6c086 !important;
    color: #fff !important; }
  .tm-report-group table.table td.col-green,
  .tm-report-group table.table th.col-green {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
    -webkit-print-color-adjust: exact;
    background-color: #56af56 !important;
    color: #fff !important; }

.wellness-center .tm-card.tm-card-stepper .tm-card-content .form-group {
  max-width: 431px; }

.birthday-fields {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }
  @media only screen and (max-width: 767.95px) {
    .birthday-fields .tm-dropdown {
      width: 100% !important;
      margin-bottom: 20px; } }
  .birthday-fields .tm-dropdown {
    margin-right: 0; }

.debug-content {
  border-bottom: 1px dashed #b93333;
  margin-bottom: -1px; }
  .debug-content:hover {
    border-bottom: none;
    background-color: #e16c69;
    color: #f8f9fd;
    cursor: help;
    position: relative; }
    .debug-content:hover:before {
      content: "data-key:" attr(data-key) " | data-audienceFallback:" attr(data-audience-fallback) " | data-defaultKey:" attr(data-default-key) " | data-id:" attr(data-id) " | data-type:" attr(data-type) " | data-lang:" attr(data-lang) " | data-audience:" attr(data-audience);
      position: absolute;
      bottom: 100%;
      padding: 5px;
      text-transform: initial;
      line-height: 1;
      font-size: 14px;
      font-weight: normal;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      background-color: #e16c69;
      white-space: nowrap; }
