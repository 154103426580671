.breadcrumb-create-survey {
  background-color: #ff000000;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-inline-start: 0;
}

.pointer{
  cursor:pointer;
}
.bold-normal{
  font-weight: 400;
}
.breadcrumb-info-container {
  p {
    margin-top: 0;
    margin-bottom: 0rem;
    font-size: 0.8rem;
    color: grey;
  }
}

.dob-container-participant-entry {
  display: flex;
  align-items: flex-end;
  margin-left: 1rem;
}

.rw-i-caret-down:before {
  content: '\E803';
  color: $midBlue;
}

.rw-widget-input {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border: none;
  border-radius: 0.4rem;
  box-shadow: 0px 1px 2px 0px rgba(150, 150, 150, 0.75);
}

// General Drop Down Styles go here
.tm-dropdown {
  margin-right: 2rem;
  width: 13rem !important;
}

.tm-dropdown.short {
  width: 8rem !important;
}

.tm-dropdown.wide {
  width: 18rem !important;
}

.modal {
  &.edit-participant-modal-wrapper {
    .modal-dialog {
      @media (min-width: 576px) {
        max-width: 700px;
        width: 700px;
      }
    }
  }

  &.add-participant-modal-wrapper {
    .modal-dialog {
      @media (min-width: 576px) {
        max-width: 820px;
        width: 820px;
      }
    }
  }
}

.participant-row {
  display: flex;
  align-items: flex-end;
}

.label-field-container {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  min-width: 0;
  flex-basis: auto;
  flex-grow: 2;

  &.phone {
    max-width: 146px;
  }

  &.icon {
    max-width: 48px;
  }

  &:last-child {
    margin-right: 0;
  }

  .tm-dropdown.short {
    margin-right: 0 !important;
    width: auto !important;
  }
}

.label-background-color {
  width:auto;
  border-radius: 10px;
}

.uk-date-order {
  display: flex;

  .uk-date-order-0 {
    order: 0;
  }

  .uk-date-order-1 {
    order: 1;
  }

  .uk-date-order-2 {
    order: 2;
  }
}

.form-container-create-survey {
  display: flex;
  flex-direction: row;
  margin-left: 5rem;

  .dropdown-container {
    .dropdown-label {
      font-weight: bold;
    }

    // Specific drop down styles here like width
    .rw-dropdown-list {
      width: 150px;
    }
  }
}

.form-group {
  margin-right: 1rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.participant-entry-field {
  @extend .rw-widget-input;
  padding-left: 1rem;
  height: 3rem;
}

.participant-entry-field.long {
  width: 20rem;
}

.participant-entry-field.short {
  width: 8rem;
}
.tag {
  margin-top: 1rem;
}
.tag-item {
  background-color: #bcbcbc;
  display: inline-block;
  font-size: 14px;
  border-radius: .4rem;
  height: 30px;
  padding: 0 5px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0.1rem 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: rgb(255, 255, 255);
  width: 22px;
  height: 22px;
  border-radius: .4rem;
  border: none;
  cursor: pointer;
  font: inherit;
  margin:0 0 0 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-header {
  display: flex;
  align-items: center;
  .step-number {
    font-size: 6rem;
    font-weight: bold;
    margin-right: 2rem;
    margin-bottom: 0;
  }
  div {
    p {
      &:first-of-type {
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.survey-margin-left {
  margin-left: 5rem;
}

.survey-step-container {
  margin-top: 100px;
  &:not(:last-child){
    &:after {
      content: '';
      display:block;
      border:none;
      color:white;
      height:1px;
      background:black;
      background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 600, from($midGrey), to(#fff0));
      margin-top: 100px;
    }
  }

  &:last-child {
    padding-bottom: 150px;
  }

  &[data-active=false] {
    pointer-events: none;
    opacity: 0.5;
  }
}

.clever-student-selection-wrapper {
  max-width: 600px;
  background: $lightMidGrey;
  padding: 25px;
}

.clever-all-grades-wrapper {
  margin-bottom: 15px;
}

.clever-student-selection {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex-basis: 25%;
    margin-bottom: 15px;
  }
}
