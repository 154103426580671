.col-print-1,
.col-print-2,
.col-print-3,
.col-print-4,
.col-print-5,
.col-print-6,
.col-print-7,
.col-print-8,
.col-print-9,
.col-print-10,
.col-print-11,
.col-print-12 {
  float: left !important;
}
.col-print-12 {
  width: 100% !important;
}
.col-print-11 {
  width: 91.66666667% !important;
}
.col-print-10 {
  width: 83.33333333% !important;
}
.col-print-9 {
  width: 75% !important;
}
.col-print-8 {
  width: 66.66666667% !important;
}
.col-print-7 {
  width: 58.33333333% !important;
}
.col-print-6 {
  width: 50% !important;
}
.col-print-5 {
  width: 41.66666667% !important;
}
.col-print-4 {
  width: 33.33333333% !important;
}
.col-print-3 {
  width: 25% !important;
}
.col-print-2 {
  width: 16.66666667% !important;
}
.col-print-1 {
  width: 8.33333333% !important;
}
.offset-print-11 {
  margin-left: 91.66666667% !important;
}
.offset-print-10 {
  margin-left: 83.33333333% !important;
}
.offset-print-9 {
  margin-left: 75% !important;
}
.offset-print-8 {
  margin-left: 66.66666667% !important;
}
.offset-print-7 {
  margin-left: 58.33333333% !important;
}
.offset-print-6 {
  margin-left: 50% !important;
}
.offset-print-5 {
  margin-left: 41.66666667% !important;
}
.offset-print-4 {
  margin-left: 33.33333333% !important;
}
.offset-print-3 {
  margin-left: 25% !important;
}
.offset-print-2 {
  margin-left: 16.66666667% !important;
}
.offset-print-1 {
  margin-left: 8.333333% !important;
}
