// Greyscale
$transparent: transparent;
$white: #f8f9fd;
$inputFill: #f3f4f1;
$lightGrey: #fafafa;
$lightMidGrey: #eaeaea;
$midGrey: #bcbcbc;
$darkGrey: #3c3c3c;
$contentGrey: #727272;

// Reds
$lightRed: #e16c69;
$red: #b93333;
$midRed: #9B5865;
$darkRed: #584649;

// Greens
$lightGreen: #a6c086;
$darkGreen: #56af56;
$darkerGreen: #3e893e;
$darkestGreen: #1d5a1d;

// Blues
$lightBlue: #3393b9;
$midBlue: #2d809f;
$darkBlue: #11526a;
$linkBlue: #1E6986;
$bgBlue: #f0f1f5;

// Yellows
$mustard: #d9c363;
