.modal-content {
  border: none;
  border-radius: 0.5rem;

  .modal-header {
    border-bottom: none;
    padding: 2rem 2rem 1.5rem;
  }

  .modal-title {
    line-height: 1;
    display: flex;
    align-items: flex-end;
  }

  .modalContent {
    padding: 0 2rem 2rem;
  }
}

.btnContainer .btn:last-child {
  margin-right: 0;
}

.btnContainer-edit-participant {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  button {
    &:first-of-type {
      margin-right: 1rem;
    }
  }
}

.btnContainer-bulk-notify {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  color: red;
  button {
    &:first-of-type {
      margin-right: 1rem;
    }
  }
}
